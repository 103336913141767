import React from 'react';
import { Link } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';

const Card = ({ date, title, image, description, link }) => {
  const formatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

  const formattedDate = formatter.format(new Date(date));

  return (
    <Link to={link} target='__blank' className='block rounded-2xl bg-white lg:p-4 group/card overflow-hidden md:overflow-visible group transition-all hover:shadow'>
      <div className="md:flex md:group-odd/card:flex-row-reverse">
        <div className="md:w-1/2">
          <div className="md:rounded-lg overflow-hidden h-full">
            {image && <GatsbyImage
              image={image.gatsbyImageData
              }
              className="w-full h-full group-hover:scale-105 transition-all"
              alt={title}
            />
            }
          </div>
        </div>

        <div className="md:w-1/2">
          <div className="py-8 px-4 sm:p-8 lg:p-4 sm:pt-10 lg:pr-14 group-even/card:md:pr-8 group-even/card:lg:pr-4 group-even/card:md:pl-10 group-even/card:lg:pl-14">
            <div className='mb-4 text-black/40 font-bold'>{formattedDate}</div>

            <h2 className="mb-4 text-[22px] sm:text-3xl md:text-[40px] font-bold leading-none">{title}</h2>

            <div
              className="prose prose-lg lg:prose-xl prose-p:font-medium prose-p:text-black/60 prose-p:leading-snug prose-strong:text-black/60 prose-a:text-current prose-a:underline hover:prose-a:opacity-60 prose-a:transition">{description}
            </div>

          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;