import React from 'react';
import HeadSection from '@/components/common/Head';

import Header from '@/components/Header';
import Footer from '@/components/Footer';

import Card from '@/sections/academy/Card';

const Academy = ({ pageContext: { posts } }) => {
  return (
    <div>
      <Header />

      <main>
        <div className="pt-20 sm:pt-40 lg:pt-52 pb-20 lg:pb-40">
          <div className="fluid-container">
            <h1 className='text-[56px] sm:text-6xl font-bold'>Academy</h1>
          </div>

          <section className="pt-2 sm:pt-10">
            <div className="fluid-container">
              <div className="space-y-6 sm:space-y-10 xl:space-y-20">

                {
                  posts.map((item, index) => {
                    return (
                      <Card
                        key={index}
                        title={item.title}
                        description={item?.description.description || ''}
                        date={item.createdAt}
                        image={item.poster}
                        link={item.uri}
                      />
                    )
                  })
                }

              </div>
            </div>
          </section>
        </div>
      </main>

      <Footer />

    </div>
  );
};

export default Academy;

export const Head = () => <HeadSection title="Academy" />;